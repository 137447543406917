
<script lang="ts" setup>
    const { t, locale, locales } = useI18n()
    // https://nuxt.com/docs/getting-started/seo-meta
    useHead({
        htmlAttrs: {
            lang: locale.value,
        },
    })

    // TODO: In the future, make seperate SEO for each page. (when we actually have different pages)
    useSeoMeta({
        charset: 'utf-8',
        title: t("company_name"),
        ogTitle: t("company_name"),

        // googleSiteVerification // For analytics
        description: t("seo.description"),
        ogDescription: t("seo.description"),
        ogType: 'website',


        contentSecurityPolicy: {
            defaultSrc: "'self' https://www.google.com",
            scriptSrc: "'self' 'unsafe-inline' 'unsafe-eval'",
            styleSrc: "'self' 'unsafe-inline' https://fonts.googleapis.com",
            imgSrc: "'self' data: ",
            fontSrc: "'self' https://fonts.gstatic.com",
            connectSrc: "'self'",
            objectSrc: "'none'",
        },

        // Set the locale.
        ogLocale: locale.value,
        ogLocaleAlternate: locales.value.map(l => typeof l === "string" ? l : l.iso!),


        author: t("footer.company_legal_name"),
        robots: 'index,follow', 
    })


</script>

<template>

    <!-- Hero of the home page. -->
    <HomeHero></HomeHero>

    <!-- All the sections -->
    <HomeServicesSection></HomeServicesSection>
    <HomeMachinesSection></HomeMachinesSection>
    <HomeAbout></HomeAbout>
    <HomeContact></HomeContact>


    <PageFooter></PageFooter>
</template>