<template>

    <section class="contact" :id="getAnchorName('contact')">
        <!-- <div class="anchor" id="contact"></div> -->

        <section class="contact-wrapper">
            <aside> 
                <header>
                    <h3 class="uppercased-spaced-text"> {{ $t('home.contact.subtitle') }} </h3>
                    <h2>{{ $t('home.contact.title') }}</h2>
                </header>
                
                <ul>

                    <!-- The adress -->
                    <li class="address">
                        <header>{{ $t('home.contact.address') }}</header>
                        <address>
                            Amsteldijk-Noord 31<br>
                            1422 XW Uithoorn<br>
                            {{ $t('home.contact.netherlands') }}<br>
                        </address>
                    </li>


                    <!-- Contact information - phone and email -->
                    <li class="phone-mail">
                        <header>{{ $t('home.contact.via') }}</header>
                        <address>
                            <span>  
                                <Icon name="octicon:mail-24"></Icon> 
                                <a href="mailto:info@ververij.nl"> info@ververij.nl </a>
                            </span>
                            <span>  
                                <Icon name="iconoir:phone"></Icon> 
                                <a href="tel:+310297562539">  +31 0297 562 539</a>
                            </span>
                        </address>
                    </li>


                    <!-- Legal information -->
                    <li class="legal-information">
                        <header>{{ $t('home.contact.business_information') }}</header>
                        <div>
                            <span>{{ $t('home.contact.kvk_number') }}:</span>34170717<br>
                            <span>{{ $t('home.contact.btw_number') }}:</span>NL 8108 16 477 B01<br>
                            <br>
                            <span>IBAN ING:</span>NL38 INGB 0000 0080 66<br>
                            <span>BIC ING:</span>INGBNL2A<br>
                            <span>IBAN Rabobank:</span>NL52 RABO 0386 3563 27<br>
                            <span>BIC Rabobank:</span>RABONL2U<br>
                        </div>
                    </li>

                </ul>

            </aside>
            
            <div class="map-wrapper">
                <iframe :title="$t('home.contact.title') + ' map'" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d549.8920894401585!2d4.842187978126586!3d52.236203180354124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5df8bcd5867b5%3A0x374fc375671a0067!2sAmsteldijk%20Noord%2031%2C%201422%20XW%20Uithoorn!5e0!3m2!1snl!2snl!4v1699967625406!5m2!1snl!2snl" width="100%" height="100%" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <!-- <iframe
                    width="100%"
                    height="100%"
                    frameborder="0" style="border:0"
                    referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place?&q=Amsteldijk-Noord 31, Uithoorn"
                    allowfullscreen>
                </iframe> -->
            </div>
        
        </section>

    </section>

</template>

<style scoped>

    section.contact {
        background: #ffffff;
        padding: 5em;
        --border-radius: 12px;
        /* padding-top: 5em; */
        /* height: 100%; */
    }

    section.contact > section {
        display: flex;
        flex-direction: row;
        background: var(--light-grey-background);
        max-width: 1800px;
        margin-left: auto;
        margin-right: auto;
        border-radius: var(--border-radius);
    }

    .map-wrapper, .map-wrapper iframe {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    /* The side with all the information. */
    section > aside {
        flex-basis: 40%;
        padding: 5em;
        padding-right: 2em;
        box-sizing: border-box;
        min-width: 450px;
    }

    .map-wrapper {
        flex-grow: 1;
        background: #000000;
    }

    h3 {
        font-size: 1.25em;
        font-weight: 400;
        margin-bottom: 0.5em;
        color: var(--green-accent-color);
    }

    h2 {
        font-size: 2.5em;
        margin-bottom: 1em;
    }

    ul {
        display: flex;
        flex-direction: column;
        /* Gap between address, contact, business information */
        gap: 3em; 
    }

    /* Header of the "Adresgegevens", etc */
    li header {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    /* The sectons underneath the header */
    address, li div {
        font-style: normal;
        font-size: 1.1rem;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        gap: 0.2em;
        font-variation-settings: 'wght' 300;
    }


    /* For the phone and mail section */
    .phone-mail address span {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75em;
    }

    .legal-information div {
        display: block;
    }
    
    .legal-information span {
        /* Prevent this for being copied. */
        user-select: none;
        margin-right: 0.33em;
    }

    /* Links to the email and tel phone */
    address a {
        /* color: var(--green-accent-color); */
        color: black;
        text-decoration: underline;
        /* Make italic */
        font-style: italic;
    }


    /* If smaller than 1080 - wrap it and but the map underneath. */
    @media screen and (max-width: 1080px) {
        

        section.contact > section {
            flex-direction: column;
        }

        .map-wrapper, .map-wrapper iframe {
            border-radius: 0 0 var(--border-radius) var(--border-radius);
        }

        section > aside  {
            min-width: unset;
            padding: 5em;
            padding-right: 5em;
        }
    
        ul {
            flex-direction: row;
            justify-content: left;
            flex-wrap: wrap;
        }

        /* First li in ul */
        ul > li:first-child {
            /* flex-basis: 100%; */
            order: 1;
        }

        /* Last cild in the ul */
        ul > li:last-child {
            /* flex-basis: 100%; */
            order: 2;
        }
        
        .map-wrapper {
            display: flex;
            align-items: stretch;   
            min-height: 450px;
        }

        .map-wrapper iframe {
            min-height: 450px;
        }
    }

    /* Smaller than 600px for phones */
    @media (max-width: 600px) {

        section.contact {
            padding: 0em;
            padding-top: 1em;
            --border-radius: 0px;
        }

        section > aside {
            padding: 2em;
            padding-top: 3em;
            padding-right: 2em;
        }

        section > aside > ul {
            gap: 2em;
        }

        section > aside > ul > li {
            flex-basis: 100%;
        }

        .map-wrapper {
            display: flex;
            align-items: stretch;   
            min-height: 300px;
        }

        .map-wrapper iframe {
            min-height: 300px;
        }
    }


</style>