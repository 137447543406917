<script setup lang="ts">

    const cards = [
        {
            content: "finishing",
            image: await import("~/assets/images/finishing.png"),
        },
        {
            content: "dyeing",
            image: await import("~/assets/images/dyeing.png"),
        },
        {
            content: "reconditioning",
            image: await import("~/assets/images/reconditioning.png"),
        }, 
    ]

</script>

<template>

    <section class="services" :id="getAnchorName('services')">
        <header>
            <h3 class="uppercased-spaced-text"> {{ $t('home.services.header.subtitle') }} </h3>
            <h2> {{ $t('home.services.header.title') }}</h2>
        </header>
        <p>
            {{ $t('home.services.header.text') }}
        </p>

        <ul>

            <li v-for="card in cards">
                <img :src="card.image.default" :alt="$t(`home.services.${card.content}.title`)">
                <h3 class="uppercased-spaced-text"> {{ $t(`home.services.${card.content}.title`) }} </h3>
                <p>
                    {{ $t(`home.services.${card.content}.text`) }}
                </p>
            </li>
        </ul>

    
    </section>

</template>

<style scoped>
    
    section.services {
        background-color: var(--dark-background);
        width: 100%;
        color: var(--light-text-color);
        font-family: "Rubik";
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        /* padding: 5em; */
        padding-bottom: var(--padding);
        --padding: 5em;
    }

    section > header {
        text-align: center;
        padding-top: var(--padding);
    }

    h3 {
        font-size: 1.25em;
        font-weight: 400;
        margin-bottom: 0.5em;
        /* color: var(--green-accent-color); */
        color: gray;
    }

    h2 {
        font-size: 2.5em;
        margin-bottom: 1em;
    }

    p {
        max-width: 820px;
        font-size: 1.25em;
        text-align: center;
        line-height: 150%;
        padding-left: var(--padding);
        padding-right: var(--padding);
        font-variation-settings: 'wght' 300;
    }

    ul {
        display: flex;
        /* max-width: 820px; */
        gap: 5vw;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        align-items: stretch;
        margin-top: 3.5em;
    }

    li:hover {
        cursor: pointer;
    }

    /* Make a card from the list item */
    li {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: top;
        /* height: 580px; */
        max-width: 600px; 

        overflow: hidden;
        background-color: var(--dark-grey-background);
        border-radius: 10px;
    }

    li h3 {
        margin-top: 1.5em;
        margin-bottom: 1em;
        font-size: 1.5em;
        /* margin-bottom:  */
        color: var(--green-accent-color);
    }

    li p {
        padding: 0 1.5em;
        margin-bottom: auto;
        margin-bottom: 2em;
    }

    li img {
        height: 280px;
        width: 100%;
        object-fit: cover;
        /* Cover */
    }

    /* Media query below 1480px */
    @media (max-width: 1480px) {

        /* Decrease the width and gap to allow staying in a row */
        li {
            width: 330px;
        }

        ul {
            gap: 3vw;
        }
    }


    /* Decrease the width and gap to allow staying in a row */
    @media (max-width: 1080px) {

        li {
            width: 300px;
        }
     
    }

    /* Below 1080px - allow them all underneath.*/
    @media (max-width: 980px) {

        li {
            width: unset;
         }


        ul {
            flex-direction: column;
            padding-left: var(--padding);
            padding-right: var(--padding);
        }

    }

    /* Media query above 1480 */
    @media (min-width: 1480px) {

        ul {
            gap: 3vw;
        }

        /* Show cards next to each other. */
        li {
            width: 400px;
            min-height: 580px;
        }
    }


    /* Below 600 pixels for phones - remove padding and stuff */
    @media (max-width: 600px) {

        h2 {
            font-size: 1.5em;
            margin-bottom: 2em;
        }

        h3 {
            font-size: 1em;
        }
        
        li h3 {
            font-size: 1.25em;
        }

        section.services {
            padding: 2em;
            padding-top: 1em;
            --padding: 2em;
        }

        p {
            padding-left: 0;
            padding-right: 0;     
            text-align: left;   
        }

        li p {
            padding: 0 1em;
        }

        ul {
            padding-left: 0;
            padding-right: 0;
            gap: 2em; 
            font-size: 1em;
        }

    }


</style>