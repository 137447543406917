<script lang="ts" setup>
    const goToSection = useGoToSection()
</script>

<template>

    <header :id="getAnchorName('home')">
    
        <Navbar></Navbar>

        <section class="hero">
            <h1> {{ $t("company_name") }} </h1>
            <p>
                {{ $t("home.slogan") }}
            </p>

            <button class="green-round-button" @click="goToSection('contact')">
                {{ $t("home.contact_us") }}
            </button>
        </section>

    </header>

</template>

<style  scoped>

    header {
        background-image: url(@/assets/images/fabric-background.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        min-height: 900px;
        height: 100%;
    }

    section.hero {
        padding: 2em 3em;
        font-variation-settings: 'wght' 400;
        font-family: "Rubik";
    }

    h1 {
        font-size: 2em;
        font-family: "Rubik";
        color: var(--black-text-color);
        /* text-align: center; */
        margin-top: 2em;
    }

    .hero p {
        margin-top: 0.5em;
        font-size: 3.5em;
        font-family: "Rubik";
        font-variation-settings: 'wght' 400;
        color: var(--black-text-color);
        /* text-align: center; */
        max-width: 15em;
        line-height: normal;
    }

    button {
        margin-top: 2em;
        transition: color 0.5s ease-in-out;
    }

    button:hover, button:focus, button:active {
        color: #dddddd;
    }

    /* Media query above 2000px */
    @media (min-width: 2000px) {
        header {
            background-position: top;
        }
    }

    /* Media query for phones (under 600 pixels wide) */
    @media (max-width: 600px) {
        section.hero {
            padding: 1em 1.5em;
            position: absolute;
            top: 50vh;
            transform: translateY(-50%)
        }

        h1 {
            font-size: 1.4em;
            /* padding-right:4em; */
        }

        .hero p {
            font-size: 2.5em;
            /* text-align: center; */
            max-width: 100%;
        }
    }

    /* Smaller than 370px */
    @media (max-width: 450px) {

        h1 {
            font-size: 1.25em;
        }

        .hero p {
            font-size: 2.5em;
            padding-right: 0.5em;
        }

        button {
            font-size: 1em;
        }


    }

</style>