<script lang="ts" setup>
    const yearMade = 2023

    const currentYear = new Date().getFullYear()
    const yearText = currentYear === yearMade ? currentYear : `${yearMade} - ${currentYear}`
</script>

<template>
    <div class="footer-wrapper">
        <footer>
            <img src="@/assets/images/smit-eend-v3.svg" alt="Smit Eend">
            <span>{{ $t('footer.credits') }}</span>
            <span>© {{yearText}} {{  $t('footer.company_legal_name') }}</span>
        </footer>
    </div>
</template>

<style scoped>
    
    /* Used to get some whitespace between elemnt above. */
    .footer-wrapper {
        width: 100%;
        padding-top: 4em;
        background: #ffffff;
    }

    footer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: center;
        justify-content: center;
        background: var(--light-grey-background);
        padding: 2em;
        padding-top: 3.5em;
        box-sizing: border-box;
        font-family: "Rubik";
        font-variation-settings: 'wght' 300;
    }

    img {
        position: absolute;
        top: -90px;
        margin-left: 10px;
        height: 125px;
    }

    /* Smaller for phones - below 600px */
    @media (max-width: 600px) {
        .footer-wrapper {
            padding-top: 5em;
        }

        /* Make the duck smaller */
        img {
            top: -40px;
            height: 80px;
        }

        /* Decrease font size. */
        footer {
            font-size: 0.8em;
            padding-top: 4.5em;
        }
    }

</style>