
export default function getAnchorName(section: string) {
    const { t } = useI18n()
    return getAnchorNameForSection(section, t)
}


export function useGoToSection() {
    // Need to be initialized at start of setup.
    const router = useRouter()
    const { t } = useI18n()

    return (section: string) => {
        const anchorName = getAnchorNameForSection(section, t)
        router.push({ hash: `#${anchorName}`})
    }
}

type Translate = ReturnType<typeof useI18n>['t']
function getAnchorNameForSection(section: string, t: Translate) {
    const anchorName = t(`sections.${section}`)
    return anchorName.replace(/\s+/g, '-').toLowerCase()
}
