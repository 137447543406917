<template>

    <section class="machines-section" :id="getAnchorName('machines')">

        <img src="@/assets/images/smit-machine.jpg" :alt="'Smit ' + $t('home.machines.title')"> 
        <section>
            <header>
                <h3 class="uppercased-spaced-text"> {{  $t('home.machines.subtitle') }}</h3>
                <h2> {{ $t('home.machines.title') }} </h2>
            </header>
            <p>
                {{ $t('home.machines.text') }}
            </p>
            <button class="green-round-button" v-if="false">
                Bekijk onze machines
            </button>
        </section>
    </section>


</template>


<style scoped>

    section.machines-section {
        width: 100%;
        background-color: var(--dark-background);
        color: var(--light-text-color);
        font-family: "Rubik";
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        box-sizing: border-box;
        gap: 5em;
        padding: 5em;
        padding-top: 2.5em;
        scroll-margin-top: 5em; 
    }


    section > img {
        width: 600px;
        height: min(400px, 100%);
        
        border-radius: 1em;
        /* height: 100%; */
        object-fit: cover;
    }

    h3 {
        font-size: 1.25em;
        font-weight: 400;
        margin-bottom: 0.5em;
        /* color: var(--green-accent-color); */
        color: gray;
    }

    h2 {
        font-size: 2.5em;
        margin-bottom: 1em;
    }


    section > p {
        max-width: 650px;
        font-size: 1.25em;
        text-align: left;
        line-height: 150%;
        font-variation-settings: 'wght' 300;
    }

    button {
        margin-top: 2em;
    }

    /* Below 600 pixels */
    @media (max-width: 600px) {

        section.machines-section {
            padding: 2em;
            padding-top: 1em;
            gap: 2em;
        }

        section > img {
            width: 100%;
            height: 300px;
        }

        h2 {
            font-size: 1.5em;
        }

        h3 {
            font-size: 1em;
        }
    }

</style>