<script setup lang="ts">
    const { t, locale, locales } = useI18n()
    const localePath = useLocalePath()
    const router = useRouter()


    const languages = computed(() => {
        return locales.value.map((l) => {
            return {
                code: typeof l === "string" ? l : l.code,
                path: localePath(router.currentRoute.value.path, typeof l === "string" ? l : l.code),
            }
        })
    })

    // function changeLanguage(locale: string) {
    //     const path = localePath(router.currentRoute.value.path, locale)
    //     router.push(path)
    // }

</script>

<template>

    <ul class="language-selector">        
    
        <template v-for="l of languages">
            <li :class="{selected: l.code == locale}">
                <nuxt-link :to="l.path">{{ l.code.toUpperCase() }}</nuxt-link>
                <!-- <a :href="l.path" @click="changeLanguage(l.code)">{{ l.code.toUpperCase() }}</a> -->
            </li>
        </template>

    </ul>

</template>


<style scoped>

    ul {
        display: flex;
        flex-direction: row-reverse;
        gap: 0.74em;
        background: #e3e3e3;
        padding: 0.5em;
        box-sizing: border-box;
        border: 1px solid #e3e3e3;
        z-index: 200;
    }

    li {
        /* underlined */
        cursor: pointer;
        font-weight: 100;
        font-size: 0.75em
    }

    li a {
        text-decoration: none;
        color: inherit;
    }


    li.selected {
        color: var(--green-accent-color);
        /* Underline */
    }

    li.selected a {
        /* underline */
        text-decoration: underline;
    }


</style>