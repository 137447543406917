<template>

    <section class="about">
        <div class="anchor" :id="getAnchorName('about')"></div>
        <header class="quote">
            <h2><Icon name="fa:quote-right"></Icon> {{ $t('home.about.quote') }}</h2>
        </header>
        <!-- <img src="@/assets/images/fabric-transition.png"> -->

        
        <article>
             <img src="@/assets/images/family.png" alt="Smit Family">
            <div class="wrapper">
                <header>
                    <h3 class="uppercased-spaced-text"> {{ $t('home.about.subtitle') }} </h3>
                    <h2>  {{ $t('home.about.title') }} </h2>
                </header>
                <p>
                    {{ $t('home.about.text.1') }}
                </p>
                <p>
                    {{ $t('home.about.text.2') }}
                </p>
                <p>
                    {{ $t('home.about.text.3') }}
                </p>
            </div>
        
        </article>

    </section>

</template>

<style scoped>


    section.about {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
    }

    .anchor {
        scroll-margin-top: -180px;
    }


    /* The quote header */
    section.about > header {
        /* Include the fabric transition in the background. */
        background-image: url("@/assets/images/fabric-transition.png");
        background-size: cover;
        height: 400px;
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 5em;
    }

    section.about > header > h2 {
   
        font-size: 2.5em;
        margin-bottom: -0.5em;
        color: var(--black-text-color);
        text-align: center;
        margin-top: auto;
        text-transform: uppercase;
        letter-spacing: 0.20em;
    }

    h2 > .icon {
        margin-right: 0.5em;
    }


    /* Article contains the section about the family */
    article {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 5em;
        padding-top: 0em;
        width: 100%;
        gap: 5em;
    }

    article > div.image-wrapper {
        /* width: 40vw; */
        width: 50%;
        /* height: 100%; */
        height: auto;
        /* cover */
        border-radius: 10px;
        margin-bottom: 2em;
        overflow: hidden;
    }


    article > img {
        border-radius: 10px;
        height: min(40vw, 700px);
        max-width: 50%;
        object-fit: cover;
        object-position: top;

        /* Get the center of the image */

        margin-bottom: 2em;
    }

    h3 {
        font-size: 1.5em;
        font-weight: 400;
        margin-bottom: 0.5em;
        color: var(--green-accent-color);
    }

    h2 {
        font-size: 2.5em;
        margin-bottom: 1em;
    }
    
    article p {
        max-width: 650px;
        font-size: 1.25em;
        text-align: left;
        line-height: 150%;
        font-variation-settings: 'wght' 300;    
        margin-bottom: 1em;
    }


    /* media query for below 1450px */
    @media screen and (max-width: 1450px) {
        section.about > header > h2 {
            font-size: 2em;
        }
      
        /* Move the image to the top. */
        article {
            flex-direction: column;
            gap: 2em;
            overflow: hidden;
        }

        article > img {
            max-width: unset;
            height: 400px;
            width: 800px;
        }

        /* Make the article wider  */
        article p {
            max-width: 800px;
        }
    }

    /* Media query above 1900px */
    @media (min-width: 1900px) {
        section.about > header {
            background-position: bottom;
        }
    }

    /* Below 600px for phones */
    @media (max-width: 600px) {

        /* Make fonts and spacing smaller. */
        section.about > header {
            padding: 2em;
        }

        section.about > header > h2 {
            font-size: 1.5em;
        }

        article {
            padding: 2em;
            padding-top: 0em;
            gap: 1em;
        }

        h2 {
            font-size: 1.5em;
        }

        h3 {
            font-size: 1em;
        }

        /* Make the image fill the whole width */
        article > img {
            height: 300px;
            width: 100vw;
            border-radius: 0px;
        } 
       
    }


</style>