<script lang="ts" setup>

    const pageNames = ["home", "services", "machines", "about", "contact"];

    // To show the links
    let showLinks = ref(false)

</script>

<template>

    <nav>
        
        <div class="logo">
            <img src="@/assets/images/smit-logo.png" :alt="`${$t('company_name')} Logo`"/>
        </div>
        <button title="Menu Button" class="menu-button" @click="showLinks = !showLinks" :class="{'active': showLinks}">
            <Icon name="ic:round-menu"></Icon>
        </button>

        <ul class="page-links" :class="{'show': showLinks }">
            
            <!-- Logo in the menubar if needed. -->
            <li class="logo"><img src="@/assets/images/smit-logo.png" :alt="`${$t('company_name')} Logo`"/></li>
            
            <!-- Language selector -->
            <LanguageSelector></LanguageSelector>

            <!-- All the page names and links -->
            <li v-for="page of pageNames" @click="showLinks = false"><a :href="`#${getAnchorName(page)}`">{{ $t(`sections.${page}`) }}</a></li>
            
            <!-- <button class="current-language"> NL </button> -->
            <li class="close-menu" @click="showLinks = false">
                <Icon name="material-symbols-light:close"></Icon>
            </li>

        </ul>
    </nav>

</template>

<style lang="css" scoped>

    .language-selector {
        position: absolute;
        top: 0em;
        right: 0em;
    }

    nav {
        width: 100%;
        box-sizing: border-box;
        padding: 2em 3em;
        justify-content: space-between;
        align-items: center;
        display: flex;
    }

    img {
        height: 5em;
        cursor: pointer;
    }

    li {
        list-style: none;
        cursor: pointer;
    }


    a {
        text-decoration: none;
        color: var(--black-text-color);
        transition: color 0.33s ease-in-out;
    }

    li:hover, a:hover {
        color: var(--green-accent-color);
    }

    ul.page-links {
        display: flex;
        gap: 4em;
        font-size: 1em;
        font-family: "Rubik";
    }

    button.menu-button {
        position: absolute;
        padding: 0.25em;
        padding-right: 0.5em;
        right: 0em;
        top: 2em;
        font-size: 2em;
        background: #e3e3e3;
        color:  black;
        /* background: rgb(0, 0, 0); */
        transition: background 0.33s ease-in-out;
        /* border-radius: 5px; */
    }

    /* When clicked on the menu-button */
    button.menu-button:focus, button.menu-button:active, button.active {
        background: var(--green-accent-color);
        color: white;
    }   

    button {
        padding: 0em;
        background: none;
        border: none;
        cursor: pointer;
        display: block;
    }

    button.menu-button, .close-menu, li.logo {
        display: none;
    }

    @keyframes slide-up {
        from {
            margin-top: 100%;
            height: 300%;
        }
        to {
            margin-top: 0%;
            height: 100%;
        }
    }


    /* Below 800px */
    @media (max-width: 800px) {


        ul.page-links.show + body {
         position: fixed;
        }

        nav {
            flex-direction: column;
            gap: 1em;
            padding-bottom: 0px;
            padding-top: 3vh;
        }

        ul.page-links {
            /* display: none; */
            flex-direction: column;
            gap: 2em;
            padding-top: 2em;
            justify-content: top;
            text-align: center;

            position: fixed;
            background: #e3e3e3;
            top: 0;
            height: 100vh;
            width: 100vw;
            box-sizing: border-box;
            z-index: 100;

            font-size: 1.5em;
            
            transition: all 0.1s cubic-bezier(0.215, 0.610, 0.355, 1) 0.25s;

            /* Margin */
            margin-top: 100vh;
            visibility: hidden;
        }

        ul.page-links.show {
            margin-top: 0em;
            visibility: visible;
            transition: margin 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0.25s;
        }

        li.logo {
            display: block;
        }
        li.logo img {
            height: 4em;
        }

        img {
            height: 7.5em;
        }

        button.menu-button {
            display: flex;
        }

        .close-menu, li.logo {
            display: block;
        }

        ul li:first-of-type a {
            color: var(--green-accent-color);
            /* display;  */
        }

        .close-menu {
            display: block;
            font-size: 1.5em;
        }

        .language-selector {
            position: relative;
            top: unset;
            right: unset;
            align-self: center;
            padding: 0;
            font-size: 1.1em;
            margin-top: -1em;
        }

    }


</style>